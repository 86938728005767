<script setup lang="ts">
import { twMerge } from "tailwind-merge";
import { HTMLAttributes, computed } from "vue";
import * as accordion from "@zag-js/accordion";
import { useMachine, normalizeProps } from "@zag-js/vue";

import { SvgIcon } from "../";

const props = withDefaults(
	defineProps<{
		id: string;
		sections: string[];
		borderBottom?: boolean;
		borderTop?: boolean;
		multiple?: boolean;
		open?: string[];
	}>(),
	{
		borderBottom: false,
		borderTop: false,
		multiple: false,
		open: () => []
	}
);
const [state, send] = useMachine(
	accordion.machine({
		id: props.id,
		collapsible: true,
		multiple: props.multiple,
		value: props.open
	})
);

const api = computed(() => accordion.connect(state.value as any, send, normalizeProps));

function start(el: Element): void {
	(el as HTMLDivElement).style.height = el.scrollHeight + "px";
}

function end(el: Element): void {
	(el as HTMLDivElement).style.height = "";
}

function getProps(id: string): HTMLAttributes {
	const data = api.value.getItemProps({ value: id });
	delete data.hidden;
	return data;
}
</script>

<template>
	<div
		v-bind="api.rootProps"
		:class="twMerge([
      'bg-black-light text-white',
      $attrs.class as string || '',
			api.value[0] === id && 'pb-8'
    ])"
	>
		<div v-for="section in sections" :key="section">
			<button
				v-bind="api.getItemTriggerProps({ value: section })"
				class="flex w-full justify-between items-center py-4"
				:class="{ 'border-t-gray-semi-dark border-t': borderTop }"
			>
				<slot :name="`title-${section}`" />
				<SvgIcon
					name="chevron-down"
					class="duration-200 ease-in-out"
					:class="{ 'rotate-180': api.value.includes(section) }"
				/>
			</button>
			<div :class="{ 'border-t border-dashed border-brown': borderBottom }">
				<Transition name="expand" @enter="start" @after-enter="end" @before-leave="start" @after-leave="end">
					<div
						v-show="getProps(section)['data-state' as keyof HTMLAttributes] === 'open'"
						v-bind="getProps(section)"
						class="py-4"
					>
						<slot :name="`content-${section}`" />
					</div>
				</Transition>
			</div>
		</div>
	</div>
</template>

<style scoped>
.expand-enter-active,.expand-leave-active{@apply duration-200 ease-in-out overflow-hidden}.expand-enter-from,.expand-leave-to{@apply !h-0 opacity-0}
</style>
